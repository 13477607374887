import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"
import Container from "../container"
import { isBrowser } from "../../utils/is-browser"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) => props.theme.ziHeader};
  transition: color 0.3s, transform 0.3s, background-color 0.5s;

  ${(props) =>
    props.colorTheme === `bright` &&
    css`
      color: ${(props) => props.theme.colorWhite};
    `}

  ${(props) =>
    props.colorTheme === `dark` &&
    css`
      color: ${(props) => props.theme.colorBlack};
    `}

  ${(props) =>
    props.isScrolled &&
    props.colorTheme === `bright` &&
    css`
      background-color: ${(props) => props.theme.colorBlack};
    `}

  ${(props) =>
    props.isScrolled &&
    props.colorTheme === `dark` &&
    css`
      background-color: ${(props) => props.theme.colorWhite};
    `}

  ${(props) =>
    props.isHidden &&
    css`
      transform: translateY(-100%);
    `}

  @media ${(props) => props.theme.xlargeUp} {
    padding-top: ${rem(40)};
    padding-bottom: ${rem(40)};
  }

  @media ${(props) => props.theme.xlargeDown} {
    padding-top: ${rem(10)};
    padding-bottom: ${rem(10)};
  }
`

const StyledContainer = styled(Container)`
  position: relative;
  @media ${(props) => props.theme.xlargeUp} {
    width: ${rem(1380)};
  }
  @media ${(props) => props.theme.xlargeDown} {
    max-width: 100%;
  }
`

const MenuContainer = (props) => {
  const [isHidden, setHidden] = useState(false)
  const [isScrolled, setScrolled] = useState(false)
  const prevScroll = React.useRef(isBrowser() ? window.scrollY : 0)

  const winScroll = React.useCallback(() => {
    setScrolled(window.scrollY > window.innerHeight / 2)
    setHidden((prevIsHidden) => {
      if (prevIsHidden) {
        return window.scrollY >= prevScroll.current
      }

      return window.scrollY > 50 && window.scrollY > prevScroll.current
    })

    prevScroll.current = window.scrollY
  }, [])

  useEffect(() => {
    window?.addEventListener(`scroll`, winScroll)

    return () => {
      window?.removeEventListener(`scroll`, winScroll)
    }
  }, [])

  return (
    <Wrapper
      colorTheme={props.colorTheme}
      isHidden={isHidden}
      isScrolled={isScrolled}
    >
      <StyledContainer>
        <Row>
          <Col smallUp={12}>{props.children}</Col>
        </Row>
      </StyledContainer>
    </Wrapper>
  )
}

MenuContainer.propTypes = {
  colorTheme: PropTypes.any,
  children: PropTypes.node,
}

export default MenuContainer
