import React from "react"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import Link from "../link"

const ProductLink = styled(Link).attrs({ className: `styled-p-product` })`
  color: ${(props) => props.theme.colorWhite};
  text-align: center;

  span: last-of-type {
    opacity: 0.3;
  }

  @media ${(props) => props.theme.largeUp} {
    width: 14.2857143%;
  }

  @media ${(props) => props.theme.largeDown} {
    width: 50%;
    padding-bottom: ${rem(40)};
  }

  @media ${(props) => props.theme.xlargeUp} {
    position: relative;

    &::before {
      content: "";
      background: #fff;
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: ${rem(-20)};
      bottom: ${rem(-20)};
      transition: 0.3s;
      border-radius: 3px;
    }

    &:hover::before {
      opacity: 0.1;
    }
  }
`

export function Product(props) {
  return (
    <ProductLink to={`/products#${props.code.split(`—`).join(``)}`}>
      <div>{props.code}</div>
      <span dangerouslySetInnerHTML={{ __html: props.name }} />
      <br />
      <br />
      <span dangerouslySetInnerHTML={{ __html: props.description }} />
    </ProductLink>
  )
}

Product.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  code: PropTypes.string,
}
